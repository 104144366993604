var FormBuilder = FormBuilder || {};

FormBuilder.Base = (function ($) {
    'use strict';

    function init() {
        // add floating labels
        $("form.kentico-form .form-group.form-group-text").each(function (e) {
            var input = $(this).find("input.form-control, select.form-select, textarea.form-control");
            var label = $(this).find("label");

            $(this).addClass("form-floating");
            label.remove().insertAfter(input);

        });
    }

    return {
        init: init
    };

})(jQuery);

$(function () {
    FormBuilder.Base.init();
});